.nav-phone {


  &__checkbox {
    outline: none;
    display: none;
  }


  &__button {
    outline: none;
    background-color: white;
    height: 7rem;
    width: 7rem;
    position: absolute;
    top: 50%;
    right: 30%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 999;
    text-align: center;
    cursor: pointer;

  }


  &__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 32%;
    transform: translateY(-50%);
    background-image: radial-gradient(#d71343,#900d2d);
    z-index: 100;
    transition: transform .8s cubic-bezier(0.86,0,0.07,1) ;
  }

  &__nav {

    height: 100vh;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 150;
    opacity: 0;
    transition: all .8s cubic-bezier(0.68,-0.55,0.265,1.55) ;

  }

  &__list {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {

    margin: 1rem;
  }

  &__link {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

      a {
        &:link,
        &:visited {
          display: inline-block;
          font-size: 3rem;
          font-weight: 300;
          padding: 1rem 2rem;
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          background-image: linear-gradient(120deg, transparent 0%, transparent 50% ,white 50%);
          background-size: 220%;
          transition: all .3s;

          span {
            margin-right:1.5rem ;
            display: inline-block;
          }
        }

        &:hover,
        &:active {
          background-position: 100%;
          color: black;
          transform: translateX(1rem);
        }

      }
  }

  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }
  &__checkbox:checked ~ &__nav {
    width: 100%;
    opacity: 1;
  }

  &__icon {

    position: relative;
    margin-top: 3.5rem;
    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: black;
      display: inline-block;

    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;
    }
    &::before { top: -.8rem;}
    &::after { top: 0.8rem;}

  }
  &__button:hover &__icon::before {
    top: -1rem;
  }
  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-45deg);
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(45deg);
  }
}
.nav-phone__link-active {
  a {
    background-position: 100%;
    color: black !important;
    transform: translateX(1rem);
  }

}
