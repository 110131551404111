.Counter-section {
  height: 70rem;
  @media only screen and (max-width: $bp-smallest) {
    height: 81rem;
  }

  &__top {
    height: 40rem;
    background-color: black;
    padding-top: 7rem;
    .counter-block {

      color: white;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media only screen and (max-width: $bp-small) {
        flex-wrap: wrap;
        height: auto;
      }
      &__item {
        text-align: center;
        font-family: inherit;
        @media only screen and (max-width: $bp-small) {
          flex: 0 0 50%;
          margin-bottom: 2rem;
        }
        & h1 span {
          font-size: 2.8rem;
          @media only screen and (max-width: $bp-medium-2) {
            font-size: 2.2rem;
          }
          @media only screen and (max-width: $bp-small) {
            font-size: 1.8rem;
          }
        }
        & span {
          font-size: 1.8rem;
          color: #e9e9e9;
          text-transform: uppercase;
        }
      }
    }
  }

  &__bottom {
    position: relative;
    & img {
      height: 37rem;
      width: 50rem;
      margin-top: -20.5rem;
      margin-left: 5rem;
      @media only screen and (max-width: $bp-medium) {
        height: 35rem;
        width: 35rem;
        margin-top: -15rem;
        margin-left: 3rem;
      }

      @media only screen and (max-width: $bp-small) {
        height: 30rem;
        width: 30rem;
        margin-top: -10rem;
        margin-left: 3rem;
      }
    }
  }
  &__addressBlock {
    position: absolute;
    top:-5rem;
    right: 44%;
    width: 60rem;
    height: 30rem;
    background-color: white;
    box-shadow: 3px 3px 5px rgba(94, 104, 121,.288);
    padding: 2rem 3rem;

    @media only screen and (max-width: $bp-medium) {
      right: 16rem;
    }
    @media only screen and (max-width: $bp-medium-2) {
      right: 10rem;
      width: 50rem;
    }
    @media only screen and (max-width: $bp-small) {
      right: 6rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      top: 10rem;
      right: 3rem;
      width: 36rem;
      padding: 0 2rem;
      padding-bottom: 2rem;
      height: fit-content ;
    }
    .title_1 {
      letter-spacing: .1rem;
      font-size: 2.5rem;
      @media only screen and (max-width: $bp-small) {
        font-size: 2.5rem;
      }
      @media only screen and (max-width: $bp-smallest) {
        font-size: 1.8rem;
      }
    }

    p {
      margin-top: 2rem;
    }
    .content__text--btn {
      margin-top: 1rem;
      @media only screen and (max-width: $bp-medium-2) {
        margin-top: 0;
      }
      a {
        padding: .5rem 1.5rem;
        @media only screen and (max-width: $bp-medium-2) {
          margin-top: .9rem;
          margin-bottom: 0;
        }

      }
    }

  }
}