.events-section {
  margin-top: 7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
  position: relative;

  .event_card {
    border: var(--line2);
    display: flex;
    background-color: white;
    box-shadow: .2rem .8rem 1rem rgba(0, 0, 0, 0.05);
    margin-bottom: 15rem;
    height: 27rem;
    flex-direction: row;
    max-width: 85rem;
    min-width: 85rem;

    @media only screen and (max-width: $bp-medium-2) {
      max-width: 79rem;
      height: 33rem;
    }

    @media only screen and (max-width: $bp-small-1) {
      flex-direction: column;
      height: 45rem;
      max-width: 50rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      max-width: 38rem;
      height: 54rem;
    }

    @media only screen and (min-width: $bp-small) {

    }

    .image-data {
      flex: 0 0 40%;
      height: 100%;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @media only screen and (max-width: $bp-small) {

      }

      .event_date {
        width: 100%;
        height: 100%;
        background-color: #262626;
        transition: transform 5s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: $bp-smallest) {
          background-image: var(--gradient-color);
        }

        div {
          color: white;
        }

        &-day {
          font-size: 4.5rem;
          line-height: .2;
        }

        &-month {
          font-size: 3.8rem;
          text-transform: capitalize;
        }
      }


    }

    .post-data {
      background: white;
      padding: 2rem 3rem;
      position: relative;
      padding-left: 4rem;
      position: relative;

      @media only screen and (max-width: $bp-smallest) {
        padding: 1.5rem;
      }

      .title {
        font-size: 3rem;
        line-height: 1;

        @media only screen and (max-width: $bp-medium-2) {
          font-size: 2.8rem;
        }

        svg {
          color: var(--best-color-grey);
          font-size: 2.4rem;
        }
      }

      .subtitle {
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: uppercase;
        color: #a2a2a2;
        margin: 1rem 0 1.5rem 0;
      }

      .description {
        position: relative;
        font-size: 1.6rem;
        text-align: justify;
        height: 15rem;

        &::first-letter {
          margin-left: 1rem;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: .5rem;
          height: 2.5rem;
          background-image: var(--gradient-color);
          border-radius: 2rem;
        }
      }

      .cta {
        text-align: right;
        font-weight: bold;
        margin-top: 1rem;
        font-size: 1.5rem;
        position: absolute;
        bottom: 2rem;
        right: 3rem;

        @media only screen and (max-width: $bp-small) {
          bottom: unset;
        }


        a {
          background: var(--gradient-color);
          -webkit-background-clip: text;
          color: transparent;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -.5rem;
            width: 100%;
            height: .3rem;
            background: var(--gradient-color);
            transform: scale(0);
            transform-origin: left;
            transition: transform .5s;
          }

          &:hover::before {
            transform: scale(1);
          }
        }
      }
    }

    &:hover .background_image {
      transform: scale(1.5);

    }

    &:hover .event_date {
      background-color: var(--color-primary)
    }
  }
}