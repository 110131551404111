.address-section {
  margin-top: 15rem;
  height: fit-content;
  margin-bottom: 20rem;
  text-align: center;


}


.map-block {
  height: 50rem;
  margin: 8rem;

  display: flex;

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
    height: 120rem;
  }

  @media only screen and (max-width: $bp-small) {
    margin: 3rem;
  }
}

.map-add {
  width: 100%;
  height: 100%;

  @media only screen and (max-width: $bp-medium) {
    margin-bottom: 20rem;
  }

  .map-par {
    margin-bottom: 1.5rem;
    width: 100% !important;

    svg {
      margin-right: 1rem;
    }
  }
  &:not(:last-child) {
    margin-right: 6rem;
  }
  position: relative !important;
  &:after {
    content: '';
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
    bottom: -13rem;
    left: -2rem;
    @media only screen and (max-width: $bp-small) {
      width: 7.5rem;
      height: 7.5rem;
      bottom: -12rem;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 10rem;
    height: 10rem;
    background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
    top: 9rem;
    right: -2rem;

    @media only screen and (max-width: $bp-small) {
      width: 7.5rem;
      height: 7.5rem;
      top: 8rem;
    }
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;

  &:not(:last-child) {
    margin-right: 12rem;
  }
}

.marker-image {
  height: 15rem;

}

.markerBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 3rem;
  }
}

