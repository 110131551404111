.about-us-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
  padding: 5rem 5rem 8rem 5rem;


  @media only screen and (max-width: $bp-small) {
    padding: 2rem 2rem 6rem 2rem;
  }

  .content {

    height: 50rem;
    width: 100%;
    display: flex;
    text-align: left;

    @media only screen and (max-width: $bp-small-1) {
      flex-direction: column;
      height: auto;
    }

    &__text {
      flex: 0 0 50%;
      padding: 6rem 0;
      .text-div {
        width: 25rem;
        height: .6rem;
        border-radius: 1rem;
        margin-bottom: 1.5rem;
        background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
      }

      & .paragraph {
        font-size: 1.8rem;
        color: var(--best-color-grey);
        text-transform: uppercase;
        font-weight: 700;
        @media only screen and (max-width: $bp-medium-2) {
          font-size: 2.2rem ;

        }
        @media only screen and (max-width: $bp-small) {
          padding-top: .5rem !important;
          font-size: 2rem;

        }

        &-2 {
          text-transform: lowercase;
          @media only screen and (max-width: $bp-small) {

          }
        }
      }

      & .title_1 {
        text-transform: uppercase;
        font-size: 4.4rem;
        margin-bottom: 1.5rem;
        letter-spacing: .3rem;
        margin-top: -.8rem;
        color: var(--color-sec);

        @media only screen and (max-width: $bp-medium) {
          font-size: 3.4rem;
        }
        @media only screen and (max-width: $bp-small-1) {
          font-size: 4.4rem;
          margin-bottom: 3rem;
        }
        @media only screen and (max-width: $bp-small) {
          font-size: 2.8rem !important;
          margin-bottom: 1.3rem;
        }

      }



    }

    &__imgBlock {
      flex: 0 0 50%;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(8, 1fr);
      @media only screen and (max-width: $bp-small-1) {
        margin-top: -17rem;
        height: 80rem;
      }
      @media only screen and (max-width: $bp-small) {
        margin-top: 8rem;
        height: 40rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--1 {
          grid-column: 2/8;
          grid-row: 3/-1;
          z-index: 2;
          position: relative;

          @media only screen and (max-width: $bp-small-1) {
            grid-column: 4/8;
            grid-row: 3/7;
          }
          @media only screen and (max-width: $bp-small) {
            grid-column: 1/8;
            grid-row: 2/8;
          }

          &:after {
            content: "";
            position: absolute;
            top: -3rem;
            left: 3rem;
            height: 115%;
            width: 100%;
            border: 0.8rem solid var(--color-primary);
            z-index: 100;
          }
        }

        &--2 {
          grid-column: 3/9;
          grid-row: 2/8;
          z-index: 1;
          @media only screen and (max-width: $bp-small-1) {
            grid-column: 5/9;
            grid-row: 2/6;
          }
          @media only screen and (max-width: $bp-small) {
            grid-column: 2/9;
            grid-row: 1/7;
          }
        }

      }
    }
  }
}

.content__text--btn{
  margin-top: 3rem;
  transition: all .1s;

  & a {
    text-decoration: none;
    padding: 1rem 1.5rem;
    font-size: 1.8rem;
    color: white;
    background-color: black;
    position: relative;
    display: inline-block;
    width: 15rem;
    overflow: hidden;
    z-index: 1;
    @media only screen and (max-width: $bp-small-1) {
      margin-bottom: -8rem;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 3%;
      height: 100%;
      background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
      left: 0;
      border-radius: 0 50% 50% 0;
      transition: .8s;
      z-index: -1;
    }

    &:hover:before {
      width: 180%;
    }
  }

}