@import "../../components/sous-categories-item/sous-categories-item.styles";
.items-content {
  margin: 5rem 0;
  display: grid;
  padding: 0 20rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5rem;
  }
  @media only screen and (max-width: $bp-smallest) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sous-cat-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-img {
  height: 50rem;
}

.shop-header__contentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}