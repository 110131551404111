.view-product {
  display: grid;
  grid-template-rows: 7rem 48rem 7rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 12rem;

  @media only screen and (max-width: $bp-large) {
    grid-template-rows: 7rem 50rem 7rem;

  }
  @media only screen and (max-width: $bp-medium) {
    grid-template-rows: 7rem auto 7rem;

  }
  @media only screen and (max-width: $bp-small) {
    padding: 0 1rem;

  }

  &-topLeft {
    grid-column: 1/-1;
    //border-right: var(--line);
    border-bottom: var(--line);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & div {
    @media only screen and (max-width: $bp-medium) {
        display: none;
      }
    }

  }

  &-topRight {
    border-left: var(--line);
    border-bottom: var(--line);
  }

  &-bottomLeft {
    border-right: var(--line);
    border-top: var(--line);
  }

  &-bottomRight {
    border-left: var(--line);
    border-top: var(--line);
  }

  &-content {
    grid-row: 2/3;
    grid-column: 1/-1;
    border: var(--line);
    border-left: 2px solid var(--color-grey-light-2);
    border-right: 2px solid var(--color-grey-light-2);
    display: flex;
    @media only screen and (max-width: $bp-medium) {
      flex-direction: column;
    }

    .detail {
      flex: 0 0 50%;
      padding: 2rem 2rem;

      &-title {
        font-size: 1.6rem;
        color: var(--best-color-grey);
        width: fit-content;
      }

      &-p {
        color: black;
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.2;
        margin-top: .6rem;
        margin-bottom: 2rem;
      }

      &-product-number {
        color: var(--best-color-grey);
        font-size: 1.4rem;
        padding-bottom: .2rem;
        font-weight: bold;
        border-bottom: 3px solid var(--color-grey-light-2);
        min-width: 100%;
        margin-bottom: .3rem;
      }

      &-stars {
        font-size: 1.6rem;

        svg {
          padding-right: .4rem;
          color: var(--color-primary);
        }

        margin-bottom: .5rem;
      }

      &-description-header {
        padding-right: 20rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 3px solid var(--color-grey-light-2);

        span {
          font-size: 1.4rem;
          color: var(--best-color-grey);
          font-weight: bold;
          padding: .2rem 1rem 0 1rem;
          cursor: pointer;
        }

        .active {
          background-color: var(--color-grey-light-3);
        }
      }

      &-description {
        font-size: 1.5rem;
        color: var(--best-color-grey);
        margin-bottom: 4rem;
        height: 9rem;


      }

      &-priceBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 3px solid var(--color-grey-light-2);

        margin-bottom: .5rem;

        .price {
          color: var(--best-color-grey);
          font-size: 2.8rem;
        }

        .qnt {
          color: var(--best-color-grey);
          font-size: 2rem;
          text-transform: capitalize;

        }
      }

      &-actions {
        display: flex;
        align-items: center;

        .add {
          padding: .8rem 1rem;
          margin-right: 2rem;
          background-color: var(--color-grey-light-3);
          border-color: transparent;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 1.3rem;
          letter-spacing: .2rem;
          a{
            color: black;
            text-decoration: none;
          }

          &:hover {
            background-color: var(--color-grey-light-4);
          }

        }

        .heart {
          padding: .8rem 1rem;
          background-color: var(--color-grey-light-3);
          cursor: pointer;

          &:hover {
            background-color: var(--color-grey-light-4);
          }
        }

        svg {
          font-size: 1.5rem;

        }
      }
    }

    .imageBlock {
      flex: 1;

      @media only screen and (min-width: $bp-medium) {
        width: 1rem;
      }

      .item {
        height: 42rem;
        padding: 3rem 0;
        text-align: center;

        .img {
          height: 40rem;
        }
      }
    }

  }

}

.swiper-button-prev:after, .swiper-button-next:after {
  color: black;
  font-size: 3.5rem !important;
  @media only screen and (max-width: $bp-large) {
    font-size: 3.2rem !important;
  }
}

.swiper-pagination-bullet-active {
  background: black !important;
}
.view-product-content .imageBlock .item .img {
  height: 40rem;
  width: 83%;
}