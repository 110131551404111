.footer {
  background-color: black;
  padding: 8rem 3rem 3rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;

&__social-media {
//background-color: red;
  width: 15%;
  align-self: center;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $bp-small) {
    width: 20rem;
  }

  svg {
    font-size: 3.4rem;
    fill: white;
    color: white;
    cursor: pointer;

    &:hover {
      color: var(--color-primary-dark);
    }
  }

}
  &--content {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $bp-small) {
     flex-direction: column;
      margin-bottom: 5rem;
    }
    div {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      transition: all .2s;
      flex: 0 0 25%;
      height: 20rem;
      background-color: rgba(#900d2d, .39);
      span {
        svg {
          fill: white;
          margin-right: 1rem;
        }
        font-size: 1.4rem;
        color: var(--color-grey-light-1);
        text-decoration: none;
        font-family: inherit;
        text-transform: uppercase;
        margin-bottom: 1rem;


      }
    }

  }
}
.image {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.nav {
  align-self: flex-start;
  list-style: none;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: .5rem;
  align-items: center;
  @media only screen and (max-width: $bp-small) {
    align-self: center;
  }
  &__link:link,
  &__link:visited {
    font-size: 1.4rem;
    color: var(--color-grey-light-1);
    text-decoration: none;
    font-family: inherit;
    text-transform: uppercase;
    text-align: center;
    padding: 1.5rem;
    display: block;
    transition: all .2s;
  }

  &__link:hover,
  &__link:active {
    background-color: rgba(#fff, .15);
    transform: translateY(-3px);
  }
}

.copyright {
  font-family: 'Playfair Display', serif;
  font-size: 1.6rem;
  color: var(--color-grey-light-1);
  margin-top: 3rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 70%;
  text-transform: capitalize;
  @media only screen and (max-width: $bp-small) {
    width: 100%;
  }
}
.footer-btn {
  background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
position: fixed;
  bottom: 5rem;
  right: 5rem;
  width: 5rem;
  height: 5rem;color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  z-index: 99;
  cursor: pointer;
}