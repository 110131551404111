.shop-header {
  padding-top: 10rem;
  position: relative;
  margin-bottom: 10rem;

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }
  &__top {
    height: fit-content;
    width: fit-content;
    padding: .5rem 2rem;
    z-index: 50;
    position: absolute;





    &-block {
      font-size: 1.7rem;
      text-transform: capitalize;
      display: flex;
      flex-wrap: wrap;

      & a, & div {
        text-decoration: none;
        color: black;
        cursor: pointer;
        padding-right: .1rem;
        span {
          svg {
            color: var(--color-grey-dark-2);
            font-size: 1.5rem;
          }
        }

        &:hover .linkText {
          border-bottom: 2px solid var(--color-primary);
        }
      }
    }
  }

  &__content {
    margin: 5rem 0 0 0;
    color: black !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 5rem;
    @media only screen and (max-width: $bp-medium) {
      padding: 2rem;
    }

    .title_1 {
      margin-bottom: 0;
      line-height: 2.8rem;
      letter-spacing: 1.2rem;
      text-align: center;
      @media only screen and (max-width: $bp-medium) {
        letter-spacing: .8rem;
      }
      @media only screen and (max-width: $bp-small) {
        margin-bottom: .4rem;
        letter-spacing: .2rem;
      }
    }

    & .paragraph {
      text-align: center;
      font-size: 1.5rem;
      width: 50%;
      @media only screen and (max-width: $bp-medium) {
        width: 80%;
      }
      @media only screen and (max-width: $bp-small-1) {
        width: 100%;
      }
      @media only screen and (max-width: $bp-small) {
        width: 90%;
        font-size: 1.6rem;
      }
    }
  }

  &__bottom {
    height: 6rem;
    padding: 0 5rem;
    display: flex;
    z-index: 50;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: var(--line);
    font-size: 2rem;
    font-family: inherit;
    text-transform: capitalize;
    color: var(--best-color-grey);
    font-weight: 700;
    @media only screen and (max-width: $bp-smallest) {
     padding: 0 3rem !important;

    }

    & > * {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & svg {
        margin-right: 1rem;
      }


    }

    @media only screen and (max-width: $bp-small) {
      padding: 0 2rem;
    }
    @media only screen and (max-width: $bp-smallest) {
      justify-content: center;
    }

    &-pCount {
      flex: 0 0 25%;
      line-height: 4rem;
      //justify-self: flex-start;
      color: black;
      @media only screen and (max-width: $bp-small) {
        display: none;
      }
    }

    &-sortBy {
      flex: 0 0 50%;

      @media only screen and (max-width: $bp-small) {
        flex: 1;
        justify-self: right;
      }

      .form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media only screen and (max-width: $bp-small) {
          width: 70%;
        }
        @media only screen and (max-width: $bp-smallest) {
          width: 100%;
        }

        &__input {
          font-family: inherit;
          font-size: 1.8rem;
          flex: 0 0 65%;
          color: inherit;
          background-color: var(--color-grey-light-2);
          border: none;
          padding: .7rem 2rem;
          transition: all .2s;
          height: 4rem;

          &:focus {
            outline: none;
            background-color: var(--color-grey-light-3);
          }

          &::-webkit-input-placeholder {
            font-weight: 100;
            color: var(--color-grey-light-4);
          }
        }

        &__button {
          background-color: var(--color-black);
          height: 4rem;
          padding-right: 3rem;
          padding-left: 3rem;
          flex: 1;
          color: white;
          text-transform: uppercase;
          font-size: 1.4rem;
          font-family: inherit;
          letter-spacing: .2rem;
          position: relative;
          display: inline-block;
          overflow: hidden;
          z-index: 0;
          border: none;
          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            width: 3%;
            height: 100%;
            background-color: var(--color-primary);
            left: 0;
            border-radius: 0 50% 50% 0;
            transition: .8s;
            z-index: -1;
          }

          &:hover:before {
            width: 180%;
          }

          &:focus {
            outline: none;
          }

        }

      }
    }

    &-pages {
      flex: 0 0 30%;
      cursor: pointer;
      @media only screen and (max-width: $bp-small) {
        display: none;
      }

      & .text {
        margin-right: 1rem;
        color: black;
      }

      & .number {
        padding: .2rem 1rem;
        background-color: var(--color-grey-light-2);
        border-right: 2px solid white;

        &-active {
          background-color: black;
          color: white;
        }
      }
    }

  }
}

.class-A {
  @media only screen and (min-width: $bp-small) {
    display: none;
  }
}

.tsparticles-canvas-el {
  position: absolute !important;
  //height: 100vh;
}