@import "../../components/services-section/services-section.styles";
@import "../../components/about-us-section/about-us.styles";
@import "../../components/video-section/video-section.styles";
@import "../../components/counter-section/counter-section.styles";
@import "../../components/subscribe-us-section/subscribe-us-section.styles";
@import "../../components/partners-section/partners-section.styles";
@import "../../components/references-section/references-section.styles";
@import "../../components/gallery/gallery.styles";
@import "../../components/footer/footer.styles";
@import "../../components/address-section-leaflet/address-section.styles";
@import "../../components/serach/search.styles";
@import "../../components/dwnload-section/dwnload-section.styles";
@import "../../components/phone-navigation-bar/phone-navigationbar.style";
@import "../../components/stories-section/stories-section.styles";
.home {
  //position: relative !important;
  padding-top: 13rem;
}

