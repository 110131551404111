.navigation {
  position: fixed;
  top:0;
  left: 0;
  font-size: 1.4rem;
  height: 10rem;
  display: grid;
  grid-template-columns: 22.5% 55% 22.5%;
  align-items: center;
  background-color: white;
  z-index: 100;
  width: 100%;

  @media only screen and (max-width: $bp-medium) {
    border-bottom: var(--line2);
  }




  &__left {

    padding-left: 3rem;
    height: 100%;
    @media only screen and (max-width: $bp-medium) {
      display: flex;
      align-items: center;

    }

    img {
      //height: 4.5rem;
      margin-top: 1rem;
      height: 8rem;

      @media only screen and (max-width: $bp-medium) {
        height: 4rem;
      }
    }
  }

  &__center {
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
    height: 100%;
    padding: 0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--line2);

    .link__block {
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -1rem;
        transform: translateX(-50%);
        width: 0%;
        height: .3rem;
        transition: all .3s ease-in-out;
        border-radius: 1rem;
        background-color: var(--color-sec);

      }
      &:hover {
        &:before {
          width: 100%;
        }
      }


    }
    a {
      text-decoration: none;
      color: var(--color-primary);
      font-size: 1.6rem;
      //font-weight: bold;
      text-transform: capitalize;

      svg {
        fill: var(--color-primary);
        margin-top: -.5rem;
      }
    }
    .active {
      &:before {
        width: 100%;
      }
    }

  }

  &__right-phone {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    grid-column: 3/-1;
    @media only screen and (min-width: $bp-medium) {
      display: none;
    }
  }
  &__right {
    background-color: #19323c;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2.5rem;
    transition: all .2s ease-in-out;
    @media only screen and (max-width: $bp-medium) {
      display: none;
    }
    & > * {
      transition: all .2s ease-in-out;
    }

    svg {
      fill: var(--color-primary);
    }

    .text-block {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      color: var(--color-primary);
      text-transform: capitalize;


      &__1 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      &__2 {
        font-size: 1.8rem;
      }
    }

    &:hover {
      background-color: var(--color-primary);
      cursor: pointer;

      svg {
        fill: white;
      }

      .text-block {
        color: white;
      }
    }
  }
}
.nav-active {
  visibility: visible;
  transition: all 0.5s;
}
.nav-hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}


.search-button {
  @media only screen and (min-width: $bp-medium) {
    background : var(--color-primary) !important;
  }
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  display: flex;align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }
}
.search-buttonPhone {
  background-color: white ;
  //border-radius: 0 ;

  svg {
    fill: black ;
  }

}