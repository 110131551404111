@import 'node_modules/react-modal-video/scss/modal-video.scss';
.video-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 5rem;
  position: relative;

  .video-button {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 15rem;
    width: 15rem;
    transform: translate(-50%,-50%);
    background-color: white;
    font-size: 1.8rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    cursor: pointer;


    svg {
      font-size: 5rem;
      padding: 0;
      margin: 0;
    }
  }


;

  .content {


  }

}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  @media only screen and (min-width: $bp-small-1) {
    padding: 5rem 12rem;
  }
  @media only screen and (min-width: $bp-medium) {
    padding: 10rem 20rem;
  }
  border: var(--line);
  z-index:99;
  //background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));

}

.animation-area {
  background-image: linear-gradient(to right ,var(--color-primary-dark), var(--color-primary));
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: $bp-small-1) {
    height: 65vh;
  }
}
.box-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box-area li {
  position: absolute;
  display: block;
  list-style: none;
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 20s linear infinite;
  bottom: -150px;
  //z-index: 1;
  @media only screen and (max-width: $bp-small-1) {
    width: 18px;
    height: 18px;
  }
}
.box-area li:nth-child(1) {
  left: 86%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  @media only screen and (max-width: $bp-small-1) {
    width: 70px;
    height: 70px;
  }

}
.box-area li:nth-child(2) {
  left: 12%;
  width: 30px;
  height: 30px;
  animation-delay: 1.5s;
  animation-duration: 10s;
  @media only screen and (max-width: $bp-small-1) {
    width: 20px;
    height: 20px;
  }
}
.box-area li:nth-child(3) {
  left: 70%;
  width: 100px;
  height: 100px;
  animation-delay: 5.5s;
  @media only screen and (max-width: $bp-small-1) {
    width: 80px;
    height: 80px;
  }
}
.box-area li:nth-child(4) {
  left: 42%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 15s;
  @media only screen and (max-width: $bp-small-1) {
    width: 120px;
    height: 120px;
  }
}
.box-area li:nth-child(5) {
  left: 65%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
  @media only screen and (max-width: $bp-small-1) {
    width: 30px;
    height: 30px;
  }
}
.box-area li:nth-child(6) {
  left: 15%;
  width: 110px;
  height: 110px;
  animation-delay: 3.5s;
  @media only screen and (max-width: $bp-small-1) {
    width: 80px;
    height: 80px;
  }
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-800px) rotate(360deg);
    opacity: 0;
  }
}
